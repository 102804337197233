<template>
  <div :class="wrapper">
    <template>
      <section class="section split case-management">
        <div class="split-text">
          <div class="solution-hero-h1">
            {{ $t('caseManagement') }}
          </div>
          <p class="description">
            {{ $t('caseManagementDes') }}
          </p>
        </div>
        <div class="split-img">
          <img :src="cloudinary.GOVERNMENT_CASE_MANAGEMENT">
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import cloudinary from '@/constants/cloudinary';
export default {
  name: 'CaseManagementCard',
  data() {
    return {
      title: 'CaseManagement',
      wrapper: 'solution_component_wrapper',
    };
  },
  created() {
    this.cloudinary = cloudinary;
  },
};
</script>

<style scoped="" lang="scss">
.solution-hero-h1 {
  margin-bottom: 1rem;
  @include bp-medium {
    margin-bottom: 1.5rem;
  }
  font-size: 2rem;
  line-height: 1.25em;
  font-weight: 700;
  @include bp-xsmall {
    font-size: 1.5rem;
  }
}
.split {
  @include bp-medium {
    display: flex;
  }
  align-items: center;
  padding-top: 0;

  & > div {
    flex: 0 1 48%;
  }
}
.case-management {
  z-index: 1;
  overflow: hidden;
  padding: 4rem 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: #ecfafe;
}
.split-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include bp-medium {
    margin-right: 4%;
  }
  @include bp-xmediumlarge {
    min-width: auto;
  }
  @include bp-xmedium {
    min-width: auto;
  }
  .title {
    order: 2;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    line-height: 3rem;
  }
  .description {
    order: 3;
    font-size: 1rem;
    line-height: 1.7rem;
    @include bp-xsmall {
      font-size: 1rem;
    }
  }
}
.split-img {
  display: none;
  min-width: auto;
  @include bp-medium {
    display: block;
  }
  @include bp-xlarge {
    min-width: 26rem;
  }
  img {
    width: 100%;
    display: block;
  }
}
.button-link {
  order: 4;
  margin-top: 1.5rem;
}
</style>
